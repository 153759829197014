import { Link } from 'gatsby';
import React from 'react'
import { chunk } from '../../../util/helper';
import H2 from '../../core/H2'
import H3 from '../../core/H3';
import P from '../../core/P';

export default function OurWork({ OurWork: { title, cards } }) {
    const slideArr = chunk(cards, cards?.length / 2)
    const main_title = {
        main_title: title,
    }
    slideArr[0].unshift(main_title)
    return (
        <div className='bg-white py-20 lg:px-6 md:px-0 px-4' >
            <div className=' max-w-7xl mx-auto flex flex-wrap' data-aos="fade-up">
                {(slideArr && slideArr.map((arr, i) => (
                    <div className={`md:w-1/${slideArr?.length} w-full `} key={Math.random()}>
                        {(arr && arr.map((data, j) => (
                            <div className='pt-6 md:px-6 pb-8' key={Math.random()} data-aos="fade-up">
                                {data?.main_title ?
                                    <H2 title={data?.main_title} className=" md:text-left text-center " />
                                    :
                                    <>
                                        {data?.link?.url &&
                                            <div className={`rounded-lg overflow-hidden h-auto w-full`} >
                                                <a href={data?.link?.url}>
                                                    <img
                                                        className="w-full h-auto object-cover z-0 transform hover:scale-110 transition-all duration-500"
                                                        src={data?.image?.mediaItemUrl}
                                                        width={data?.image?.mediaDetails?.width}
                                                        height={data?.image?.mediaDetails?.height}
                                                        alt={
                                                            data?.image?.altText ? data?.image?.altText : "our work"
                                                        }
                                                        // title={
                                                        //     data?.image?.altText ? data?.image?.altText : "our work"
                                                        // }
                                                        loading="lazy"
                                                    >
                                                    </img>
                                                </a>
                                            </div>
                                        }
                                        <div>
                                            <div>
                                                {data?.tags && (
                                                    <div className="mt-6">
                                                        {data?.tags.map((name) => (
                                                            <p
                                                                key={Math.random()}
                                                                className="text-xs my-1.5 mr-3 inline-flex items-center font-semibold text-shark-400 leading-sm px-3 py-1.5 bg-blue-200 rounded"
                                                            >
                                                                {name?.tag}
                                                            </p>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                                            {data?.link &&
                                                <div>
                                                    <Link to={data?.link?.url}><H3 title={data?.title} className="mt-3 text-shark-500 inline-block" /></Link>
                                                    <P className="text-shark-400 mt-3 font-medium" noPad={true} title={data?.subtitle} />
                                                </div>
                                            }
                                        </div>
                                    </>
                                }
                            </div>
                        )))}
                    </div>
                )))}
            </div>
            <div className="mt-8 flex justify-center">
                {
                    <div className="inline-flex" data-aos="fade-up">
                        <a
                            href="portfolio/"
                            className="bg-blue-500 inline-flex relative px-5 py-3 text-base leading-6 font-medium text-white hover:bg-blue-600 rounded shadow-lg"
                        >
                            Explore Our Work
                        </a>
                    </div>
                }
            </div>
        </div>
    )
}
